import React, { useState } from 'react';
import axios from 'axios';
import '../stylesheets/storing.css'; // Ensure this path is correct

const WashForm = () => {
    const [file, setFile] = useState(null);
    const [error, setError] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const [downloadLink, setDownloadLink] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [buttonColor, setButtonColor] = useState('#4CAF50');

    const handleFileUpload = (event) => {
        setFile(event.target.files[0]);
    };

    const handleFileData = async () => {
        if (!file || !file.name.endsWith('.csv')) {
            setError('Please select a CSV file.');
            setButtonColor('red');
            setShowPopup(true);
            return;
        }

        setError(null);
        setIsProcessing(true);

        const formData = new FormData();
        formData.append('phoneNums', file);

        try {
            const response = await axios.post('/api/verify', formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });

            if (response.status === 200) {
                console.log('File processing initiated, checking for download link...');
                setTimeout(() => fetchDownloadLink(), 3000);
            } else {
                throw new Error('File processing did not complete successfully.');
            }
        } catch (error) {
            console.error('Error processing file:', error);
            setError(error.response?.data?.error || 'Internal Server Error');
            setIsProcessing(false);
            setShowPopup(true);
            setButtonColor('red');
        }
    };

    const fetchDownloadLink = async () => {
        try {
            const response = await axios.get('http://localhost:4000/api/v1/download-link');
            if (response.data && response.data.link) {
                setDownloadLink(response.data.link);
                setIsProcessing(false);
                setShowPopup(true);
                setButtonColor('#4CAF50');
            } else {
                console.log('Link not ready yet, retrying...');
                setTimeout(fetchDownloadLink, 3000); // Retry after 3 seconds
            }
        } catch (error) {
            console.error('Error fetching the download link:', error);
            setError('Failed to fetch download link');
            setIsProcessing(false);
            setShowPopup(true);
            setButtonColor('red');
        }
    };

    const closePopup = () => {
        setShowPopup(false);
    };

    return (
        <div className="upload-container">
            <h1>Import file to be washed</h1>
            <input type="file" className="custom-file-upload" onChange={handleFileUpload} disabled={showPopup || isProcessing} />
            <button onClick={handleFileData} disabled={isProcessing || !file}>Import file</button>

            {showPopup && (
                <div className="popup">
                    {error && <p className="error-message">{error}</p>}
                    {!error && isProcessing && <p>Processing file, please wait...</p>}
                    {downloadLink && (
                        <div className="success-message">
                            <p>File processed successfully. Download:</p>
                            <button onClick={() => window.open(downloadLink, "_blank")}>Download Processed File</button>
                        </div>
                    )}
                    <button className="custom-popup-button" style={{backgroundColor: buttonColor}}
                            onClick={closePopup}>Close
                    </button>
                </div>
            )}
        </div>
    );
};

export default WashForm;
