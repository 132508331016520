import React from 'react';
import { Link } from 'react-router-dom';
import { FaCloudUploadAlt } from 'react-icons/fa';
import '../stylesheets/dashboard.css';

const Dashboard = () => {
  return (
    <div className="dashboard-container">
      <div className="main-content">
        <section id="data-store">
          <h2>Data Store</h2>
          <p>
            Welcome to the data storage system.<br/>
            This system aims to help users store the data of the sanitized numbers.
          </p>
        </section>

        <section id="instance-generation">
          <div className="upload">
            <Link to="/upload">
              <button className="btn">
                <FaCloudUploadAlt style={{fontSize: '30px'}}/>
                <p>
                  To store washed numbers, navigate to the Upload page<br/>
                  Ensure the file you import is a csv file with all the required information.
                </p>
              </button>
            </Link>
          </div>
          <div className="upload">
            <Link to="/wash">
              <button className="btn">
                <FaCloudUploadAlt style={{fontSize: '30px'}}/>
                <p>
                  To import file to Whats App Number Checker, navigate to the Washer page<br/>
                  Ensure the file you import is a csv file with all the required information.
                </p>
              </button>
            </Link>
          </div>
          <div className="upload">
            <Link to="/sort">
              <button className="btn">
                <FaCloudUploadAlt style={{fontSize: '30px'}}/>
                <p>
                  To sort values of washed numbers, navigate to the Sorting page<br/>
                  Ensure the file you import is a excel file with all the required information.
                </p>
              </button>
            </Link>
          </div>
        </section>
      </div>
    </div>
  );
};
export default Dashboard;
