import React, { useState } from 'react';
import axios from 'axios';
import { Navigate } from 'react-router-dom';
import '../stylesheets/login.css';

const Login = ({ onLogin, apiKey }) => {
    const [formData, setFormData] = useState({
        username: '',
        full_name: '',
        password: '',
        api_key: '',
    });

    const [isSignUp, setIsSignUp] = useState(false);
    const [error, setError] = useState(null);
    const [loggedIn, setLoggedIn] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (isSignUp) {

                await axios.post('http://localhost:4000/api/v1/user/register', formData);

                const response = await axios.post('http://localhost:4000/api/v1/user/login', formData);
                console.log('User signed in:', response.data);

                const userData = { ...response.data, full_name: formData.full_name };
                onLogin(userData);
                setLoggedIn(true);
            } else {

                const response = await axios.post('http://localhost:4000/api/v1/user/login', formData);
                console.log('User signed in:', response.data);

                const userData = { ...response.data, full_name: formData.full_name };
                onLogin(userData);
                setLoggedIn(true);
            }
        } catch (error) {
            if (error.response && error.response.data) {
                setError(error.response.data.message);
            } else {
                setError('An error occurred');
            }
        }
    };

    if (loggedIn) {
        return <Navigate to="/dashboard" />;
    }

    return (
        <div className="container">
            <div className="form-container">
                <h2>{isSignUp ? 'Sign Up' : 'Sign In'}</h2>
                <form onSubmit={handleSubmit}>
                    <div>
                        <input type="text" id="username" name="username" placeholder="Username" value={formData.username} onChange={handleChange} />
                    </div>
                    {isSignUp && (
                        <div>
                            <input type="text" id="full_name" name="full_name" placeholder="Full Name" value={formData.full_name} onChange={handleChange} />
                        </div>
                    )}
                    {isSignUp && (
                        <div>
                            <input type="text" id="api_key" name="api_key" placeholder="API Key" value={formData.api_key} onChange={handleChange} />
                        </div>
                    )}
                    <div>
                        <label htmlFor="password"></label>
                        <input type="password" id="password" name="password" placeholder="Password" value={formData.password} onChange={handleChange} />
                    </div>
                    <button type="submit">{isSignUp ? 'Sign Up' : 'Sign In'}</button>
                </form>
                <p>
                    {isSignUp ? 'Already have an account?' : "Don't have an account?"}
                    <button className="toggle-button" onClick={() => setIsSignUp(!isSignUp)}>
                        {isSignUp ? 'Sign In' : 'Sign Up'}
                    </button>
                </p>
                {error && <span style={{ color: 'red' }}>{error}</span>}
            </div>
        </div>
    );
};
export default Login;
