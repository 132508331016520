import React, { useState } from 'react';
import axios from 'axios';
import '../stylesheets/storing.css';

const UploadForm = () => {
    const [file, setFile] = useState(null);
    const [error, setError] = useState(null);
    const [successResponse, setSuccessResponse] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [buttonColor, setButtonColor] = useState('#4CAF50');
    const [overlayVisible, setOverlayVisible] = useState(false); // New state for overlay

    const handleFileUpload = (event) => {
        const uploadedFile = event.target.files[0];
        setFile(uploadedFile);
    };

    const handleFileData = async () => {
        try {
            if (!file.name.endsWith('.csv')) {
                setError('Please select a CSV file.');
                setButtonColor('red');
                setShowPopup(true);
                setSuccessResponse(null);
                return;
            }

            setError(null);

            const formData = new FormData();
            formData.append('file', file);

            const response = await axios.post('http://localhost:4000/api/v1/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            setSuccessResponse(response.data);
            setShowPopup(true);
            setButtonColor('#4CAF50');

        } catch (error) {
            console.error('Error checking numbers:', error);
            if (error.response && error.response.data) {
                setError(error.response.data);
            } else {
                setError('Internal Server Error');
            }
            setSuccessResponse(null);
            setShowPopup(true);
            setButtonColor('red');
        }
    };


    const closePopup = () => {
        setShowPopup(false);
        setOverlayVisible(false);
    };

    return (
        <div className="upload-container">
            <h1>Store Washed Data</h1>
            <input type="file" className="custom-file-upload" onChange={handleFileUpload} disabled={showPopup} />
            <button onClick={handleFileData}>Store sanitized numbers</button>

            {overlayVisible && <div className="overlay" onClick={closePopup}></div>}

            {showPopup && (
                <div className="popup">
                    <button className="custom-popup-button" style={{backgroundColor: buttonColor}} onClick={closePopup}>Close</button>
                    {error && <p className="error-message">{error}</p>}
                    {successResponse && <p className="success-message">{successResponse}</p>}
                </div>
            )}
        </div>
    );
};
export default UploadForm;
