import React, { useState } from 'react';
import axios from 'axios';
import '../stylesheets/storing.css';

const SortNumbers = () => {
    const [file, setFile] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [error, setError] = useState('');
    const [downloadLink, setDownloadLink] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [buttonColor, setButtonColor] = useState('#4CAF50');
    const [overlayVisible, setOverlayVisible] = useState(false);

    const handleFileChange = (event) => {
        setError('');
        setDownloadLink('');
        setFile(event.target.files[0]);
    };

    const handleUpload = async () => {
        if (!file) {
            setError('Please select a CSV file to upload.');
            return;
        }

        setError('');
        setIsUploading(true);
        const formData = new FormData();
        formData.append('phoneNums', file);

        try {
            const response = await axios.post('http://localhost:4000/api/v1/sort', formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            setDownloadLink(`http://localhost:4000${response.data.downloadLink}`);
            setShowPopup(true);
        } catch (err) {
            console.error('Upload error:', err);
            setError('Failed to upload and process the file.');
        } finally {
            setIsUploading(false);
        }
    };

    const closePopup = () => {
        setShowPopup(false);
        setOverlayVisible(false);
    };

    return (
        <div className="upload-container">
            <h1>Sort Numbers</h1>
            <input type="file" className="custom-file-upload" onChange={handleFileChange} disabled={isUploading} />
            <button onClick={handleUpload} disabled={isUploading || !file}>Upload and Sort</button>

            {overlayVisible && <div className="overlay" onClick={closePopup}></div>}

            {showPopup && (
                <div className="popup">

                    <p>File sorted successfully. Download:</p>
                    <button onClick={() => window.open(downloadLink, "_blank")}>Download Sorted Excel File</button>
                    <button className="custom-popup-button" style={{backgroundColor: buttonColor}}
                            onClick={closePopup}>Close
                    </button>
                    {error && <p className="error-message">{error}</p>}
                </div>
            )}
        </div>
    );
};

export default SortNumbers;
