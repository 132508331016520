import React, { useState } from 'react';
import {BrowserRouter as Router, Navigate, Route, Routes} from "react-router-dom";
import './App.css'
import UploadForm from './components/StoreNumbers';
import Login from "./components/Login";
import NavBar from "./components/NavBar";
import Dashboard from "./components/Dashboard";
import WashForm from "./components/WashFile";
import SortNumbers from "./components/SortNumbers";

function App() {
    const [user, setUser] = useState(null);

    const handleLogin = (userData) => {
        setUser(userData);
    };

    const handleLogout = () => {
        setUser(null);
    };

    return (
        <Router>
            <div className="background">
                <div className="content-wrapper">

                    {user ? (
                        <NavBar fullName={user.full_name} email={user.username} onLogout={handleLogout}/>
                    ) : (
                        <Navigate to="/"/>
                    )}
                    <Routes>
                        <Route path="/" element={<Login onLogin={handleLogin}/>}/>
                        <Route path="/dashboard" element={<Dashboard/>}/>
                        <Route path="/upload" element={<UploadForm/>}/>
                        <Route path="/wash" element={<WashForm/>}/>
                        <Route path="/sort" element={<SortNumbers/>}/>
                    </Routes>
                </div>
            </div>

        </Router>
);
}
export default App;
