import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as ChatIncLogo } from '../stylesheets/ChatInc_Dark_Crop.svg';
import '../stylesheets/navBar.css';

const NavBar = ({ fullName, email, onLogout }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const handleMouseLeave = () => {
        if (isOpen) {
            setIsOpen(false);
        }
    };

    return (
        <nav className="navbar" onMouseLeave={handleMouseLeave}>

            <Link to="/dashboard" className="logo-link">
                <ChatIncLogo className="logo" />
            </Link>

            <button className="navbar-toggle" onClick={toggleMenu}>
                <span className="bar"></span>
                <span className="bar"></span>
                <span className="bar"></span>
            </button>

            <div className={`sidebar ${isOpen ? 'active' : ''}`}>
                <ul className="sidebar-links">
                    <li></li>
                    <li>
                        <Link to="/dashboard">Dashboard</Link>
                    </li>
                    <li>
                        <Link to="/upload">Store washed numbers</Link>
                    </li>
                    <li>
                        <Link to="/wash">Whats App Number Checker</Link>
                    </li>
                    <li>
                        <Link to="/sort">Sort Numbers</Link>
                    </li>
                </ul>
            </div>
        </nav>
    );
};
export default NavBar;
